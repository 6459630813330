import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { parse, isValid } from "date-fns";
import { useSelector } from "react-redux";
import * as yup from "yup";

import { getErrorLabels } from "utils/errors";
import { getOptionsArray } from "utils/options";
import { lookupPostcode } from "services";
import ButtonOptions from "components/input/ButtonOptions";
import Error from "components/Error";
import SelectInput from "components/input/SelectInput";

import { ReactComponent as InfoIcon } from "assets/images/icons/info.svg";

const PersonalInfo = (props) => {
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [showAddressFields, setShowAddressFields] = useState(false);
  const [showPreviousAddressFields, setShowPreviousAddressFields] =
    useState(false);

  const metaData = useSelector((state) => state?.general?.metaData);

  const schema = yup.object({
    title: yup.mixed().oneOf(metaData?.titleList).required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    dob_day: yup.number().min(1).max(31).label("Date of birth date").required(),
    dob_month: yup
      .number()
      .min(1)
      .max(12)
      .label("Date of birth month")
      .required(),
    dob_year: yup
      .number()
      .min(1900)
      .max(new Date().getFullYear())
      .label("Date of birth year")
      .required(),
    dob: yup
      .string()
      .test("is-date-valid", "Not a valid date", (val) => {
        let parsed = parse(val, "yyyy-MM-dd", new Date());
        return isValid(parsed);
      })
      .required(),
    relationship_status: yup
      .mixed()
      .oneOf(metaData?.maritalStatusList)
      .required(),
    postcode: yup.string().required(),
    door_number: yup.string().required(),
    street_name: yup.string().required(),
    city: yup.string().required(),
    current_years: yup.number().max(99, "Must be 2 digits").required(),
    current_months: yup
      .number()
      .max(11)
      .label("Current address months")
      .required(),
    living_arrangements: yup
      .mixed()
      .oneOf(metaData?.residenceTypeList)
      .required(),
  });

  const emailSchema = yup.object({
    email: yup.string().email().required(),
  });

  const phoneSchema = yup.object({
    mobile: yup.string().required(),
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleButtonChange = (name, option) => {
    setData({
      ...data,
      [name]: option.value,
    });
  };

  const onProceed = () => {
    let localData = {
      ...data,
      dob: `${data.dob_year}-${data.dob_month}-${data.dob_day}`,
    };

    if (getAddressMonths() < 36) {
      localData = {
        ...localData,
        previousAddress: {
          postcode: localData.previous_postcode,
          door_number: localData.previous_door_number,
          street_name: localData.previous_street_name,
          city: localData.previous_city,
          years: localData.previous_years,
          months: localData.previous_months,
        },
      };
    }

    let validationSchema = schema;
    if (props.mode === "register" && props.data?.email) {
      validationSchema = validationSchema.concat(phoneSchema);
    } else {
      validationSchema = validationSchema.concat(emailSchema);
    }

    validationSchema
      .validate(localData, { abortEarly: false })
      .then((res) => {
        props.onUpdate(localData);
        props.onProceed();
      })
      .catch((err) => {
        setErrors(getErrorLabels(err));
      });
  };

  const handlePostcodeLookup = (field = "postcode") => {
    if (data[field]) {
      lookupPostcode(data[field]).then((res) => {
        if (field === "postcode") {
          setData({
            ...data,
            ...res,
          });

          setShowAddressFields(true);
        } else {
          setData({
            ...data,
            previous_street_name: res.street_name,
            previous_city: res.city,
          });

          setShowPreviousAddressFields(true);
        }
      });
    }
  };

  const getAddressMonths = () => {
    return parseInt(data?.current_years) * 12 + parseInt(data?.current_months);
  };

  useEffect(() => {
    if (props.data) {
      setData(props.data);

      if (props.data?.postcode) {
        setShowAddressFields(true);
      }

      if (props.data?.previous_postcode && getAddressMonths() < 36) {
        setShowPreviousAddressFields(true);
      }
    }
  }, [props.data]);

  return (
    <div className="guest-page-form">
      <div className="guest-title">Personal information</div>

      <div className="guest-content full-height">
        <div className="form-container">
          <div className="form-questions">
            <div className="form-item">
              <label className="dark">What’s your title?</label>
              <ButtonOptions
                options={getOptionsArray(metaData?.titleList)}
                selected={data?.title}
                name="title"
                onClick={handleButtonChange}
                show={3}
              />

              <Error errors={errors} name="title" />

              <OverlayTrigger
                placement="top-start"
                overlay={
                  <Tooltip>
                    If your title is not listed, please choose other.
                  </Tooltip>
                }
                delay={{ show: 250, hide: 400 }}
              >
                <span className="form-item-info">
                  <InfoIcon />
                  Why isn’t my title here?
                </span>
              </OverlayTrigger>
            </div>

            <div className="form-item">
              <label className="dark">What’s your name?</label>
              <div className="inline-items">
                <div>
                  <input
                    name="first_name"
                    placeholder="First name"
                    onChange={handleChange}
                    value={data?.first_name}
                    readOnly={props.profile}
                  />
                  <Error errors={errors} name="title" />
                </div>

                <div>
                  <input
                    name="last_name"
                    placeholder="Last name"
                    onChange={handleChange}
                    value={data?.last_name}
                    readOnly={props.profile}
                  />
                  <Error errors={errors} name="title" />
                </div>
              </div>
            </div>

            <div className="form-item">
              <label className="dark">What’s your phone number?</label>
              <input
                type="tel"
                name="mobile"
                placeholder="Mobile number"
                onChange={handleChange}
                value={data?.mobile}
              />

              <Error errors={errors} name="mobile" />
            </div>
            <div className="form-item">
              <label className="dark">What’s your email?</label>
              <input
                type="email"
                name="email"
                placeholder="Email address"
                onChange={handleChange}
                value={data?.email}
              />

              <Error errors={errors} name="email" />
            </div>

            <div className="form-item">
              <label className="dark">What’s your date of birth?</label>
              <div className="period-container">
                <div className="period-input">
                  <label style={{ marginBottom: 0 }}>Day</label>
                  <input
                    name="dob_day"
                    placeholder="DD"
                    type="number"
                    maxLength={2}
                    onChange={handleChange}
                    value={data?.dob_day}
                    readOnly={props.profile}
                  />
                </div>
                <div className="period-input">
                  <label style={{ marginBottom: 0 }}>Month</label>
                  <input
                    name="dob_month"
                    placeholder="MM"
                    type="number"
                    maxLength={2}
                    onChange={handleChange}
                    value={data?.dob_month}
                    readOnly={props.profile}
                  />
                </div>
                <div className="period-input full-year">
                  <label style={{ marginBottom: 0 }}>Year</label>
                  <input
                    name="dob_year"
                    placeholder="YYYY"
                    type="number"
                    maxLength={4}
                    onChange={handleChange}
                    value={data?.dob_year}
                    readOnly={props.profile}
                  />
                </div>
              </div>

              <Error
                errors={errors}
                name={["dob_day", "dob_month", "dob_year", "dob"]}
              />
            </div>

            <div className="form-item">
              <label className="dark">What’s your relationship status?</label>
              <ButtonOptions
                options={getOptionsArray(metaData?.maritalStatusList)}
                selected={data?.relationship_status}
                name="relationship_status"
                onClick={handleButtonChange}
                show={3}
              />
              <Error errors={errors} name="relationship_status" />
            </div>

            <div className="form-item">
              <label className="dark">What’s your address?</label>
              <div
                className="d-flex justify-content: space-between align-items-center"
                style={{ gap: 20 }}
              >
                <input
                  name="postcode"
                  placeholder="Enter postcode"
                  onChange={handleChange}
                  value={data?.postcode}
                />
                <button
                  className="dark mt-0"
                  onClick={(e) => handlePostcodeLookup("postcode")}
                >
                  Lookup
                </button>
              </div>

              <Error errors={errors} name="postcode" />
            </div>

            {showAddressFields && (
              <>
                <div className="form-item">
                  <label className="dark">What’s your house number/name?</label>
                  <input
                    name="door_number"
                    onChange={handleChange}
                    value={data?.door_number}
                  />

                  <Error errors={errors} name="door_number" />
                </div>

                <div className="form-item">
                  <label className="dark">What’s your street name?</label>
                  <input
                    name="street_name"
                    value={data?.street_name}
                    readOnly
                  />

                  <Error errors={errors} name="street_name" />
                </div>

                <div className="form-item">
                  <label className="dark">What’s your city?</label>
                  <input name="city" value={data?.city} readOnly />

                  <Error errors={errors} name="city" />
                </div>
              </>
            )}

            <div className="form-item">
              <label className="dark">
                How long have you been at your current address?
              </label>
              <div className="period-container">
                <div className="period-input">
                  <label style={{ marginBottom: 0 }}>Years</label>
                  <input
                    name="current_years"
                    placeholder="YY"
                    type="number"
                    maxLength={2}
                    onChange={handleChange}
                    value={data?.current_years}
                  />
                </div>
                <div className="period-input">
                  <label style={{ marginBottom: 0 }}>Months</label>
                  <input
                    name="current_months"
                    placeholder="MM"
                    type="number"
                    maxLength={2}
                    onChange={handleChange}
                    value={data?.current_months}
                  />
                </div>
              </div>
              <Error
                errors={errors}
                name={["current_years", "current_months"]}
              />
            </div>

            {getAddressMonths() < 36 && (
              <>
                <div className="form-item mt-3">
                  <label className="dark">What’s your previous address?</label>
                  <div
                    className="d-flex justify-content: space-between align-items-center"
                    style={{ gap: 20 }}
                  >
                    <input
                      name="previous_postcode"
                      placeholder="Enter postcode"
                      onChange={handleChange}
                      value={data?.previous_postcode}
                    />
                    <button
                      className="dark mt-0"
                      onClick={(e) => handlePostcodeLookup("previous_postcode")}
                    >
                      Lookup
                    </button>
                  </div>
                  <Error errors={errors} name="previous.postcode" />
                </div>

                {showPreviousAddressFields && (
                  <>
                    <div className="form-item">
                      <label className="dark">
                        What’s your house number/name?
                      </label>
                      <input
                        name="previous_door_number"
                        onChange={handleChange}
                        value={data?.previous_door_number}
                      />

                      <Error errors={errors} name="previous_door_number" />
                    </div>

                    <div className="form-item">
                      <label className="dark">What’s your street name?</label>
                      <input
                        name="previous_street_name"
                        value={data?.previous_street_name}
                        readOnly
                      />

                      <Error errors={errors} name="previous_street_name" />
                    </div>

                    <div className="form-item">
                      <label className="dark">What’s your city?</label>
                      <input
                        name="previous_city"
                        value={data?.previous_city}
                        readOnly
                      />

                      <Error errors={errors} name="previous_city" />
                    </div>
                  </>
                )}

                <div className="form-item">
                  <label className="dark">
                    How long did you live at this address?
                  </label>
                  <div className="period-container">
                    <div className="period-input">
                      <label>Years</label>
                      <input
                        name="previous_years"
                        placeholder="YY"
                        type="number"
                        maxLength={2}
                        onChange={handleChange}
                        value={data?.previous_years}
                      />
                    </div>
                    <div className="period-input">
                      <label>Months</label>
                      <input
                        name="previous_months"
                        placeholder="MM"
                        type="number"
                        maxLength={2}
                        onChange={handleChange}
                        value={data?.previous_months}
                      />
                    </div>
                  </div>
                  <Error
                    errors={errors}
                    name={["previous_years", "previous_months"]}
                  />
                </div>
              </>
            )}

            <div className="form-item">
              <label className="dark">Living Arrangements</label>
              <ButtonOptions
                options={getOptionsArray(metaData?.residenceTypeList)}
                selected={data?.living_arrangements}
                name="living_arrangements"
                onClick={handleButtonChange}
                show={3}
              />
              <Error errors={errors} name="living_arrangements" />
            </div>
          </div>

          <div className="form-item mt-auto">
            <button className="light-blue full" onClick={onProceed}>
              {props.profile ? (
                <>Save changes</>
              ) : (
                <>Let’s review your details</>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfo;
